<template>
  <b-form-group label="Cidade" label-for="cidade" label-class="font_size_label cidade">
    <validation-provider #default="{ errors }" name="Cidade">
      <v-select
        id="cidade"
        :options="options"
        v-model="selected"
        :placeholder="placeholder"
        label="descricao"
        :clearable="true"
        multiselect
        multiple
        :disabled="loading || disabled"
      >
        <span slot="no-options">Nenhuma opção selecionável.</span>
      </v-select>
      <small v-if="errors[0]" class="text-danger">
        {{ errors[0] }}
      </small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BSpinner,
  BFormDatepicker,
  BFormTextarea,
  BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BFormDatepicker,
    BFormTextarea,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    value: {
      required: true,
    },
    optionsConsulted: {
      required: false,
      type: Array,
      default: () => [],
    },
    unidadeOperacional: {
      required: false,
    },
    regiao: {
      required: false,
    },
    estado: {
      required: false,
    },
    departamento: {
      required: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: this.value,
      placeholder: "Carregando...",
      loading: true,
      parametros: {
        params: {
          paginacao: false,
        },
      },
    };
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    verificaSeTemValor(item) {

      if (typeof item === 'object' && item !== null) {
        return Object.keys(item).length > 0;
      }

      return item != undefined && item != null && item.length > 0;
    },
    setResponse(data) {
      this.options =
        typeof data != "object"
          ? []
          : data.map((cidade) => {
              return {
                id_cidade: cidade.id_cidade,
                descricao: cidade.uf + " - " + cidade.nome,
              };
            });
    },
    async setOptions() {
      let temUnidadeOperacional = this.verificaSeTemValor(
          this.unidadeOperacional
        ),
        temRegiao = this.verificaSeTemValor(this.regiao),
        temEstado = this.verificaSeTemValor(this.estado),
        temDepartamento = this.verificaSeTemValor(this.departamento)

      this.selected = null;

      if (temDepartamento) {
        return this.getEstadoCidade([this.departamento.uf]);
      }

      if (temUnidadeOperacional) {
        return this.getUnidadeCidade(this.unidadeOperacional.map((x) => x.id_unidade));
      }

      if ((temEstado && temRegiao) || temEstado) {
        return this.getEstadoCidade(this.estado.map((x) => x.uf));
      }

      if (temRegiao) {
        return this.getRegiaoCidade(this.regiao.map((x) => x.id_regiao));
      }

      return this.getCidades();
    },

    async getCidades() {
      await this.$http
        .get(this.$api.cidade(), this.parametros)
        .then(({ data }) => {
          this.setResponse(data);
        });
    },
    async getUnidadeCidade(idsUnidades) {
      await this.$http
        .get(this.$api.cidadeUnidade(idsUnidades), this.parametros)
        .then(({ data }) => {
          this.setResponse(data);
        });
    },

    async getRegiaoCidade(idsRegiao) {
      await this.$http
        .get(this.$api.cidadeRegiao(idsRegiao), this.parametros)
        .then(({ data }) => {
          this.setResponse(data);
        });
    },

    async getEstadoCidade(uf_estados) {
      await this.$http
        .get(this.$api.cidadeEstado(uf_estados), this.parametros)
        .then(({ data }) => {
          this.setResponse(data);
        });
    },
  },
  watch: {
    selected: function (valor) {
      this.$emit("selected", valor);
    },
    options: function (valor) {
      this.loading = this.options.length == 0;
      this.placeholder = this.loading
        ? "Nenhum registro encontrado"
        : "Selecione a cidade";
      this.$emit("options", valor);
    },
    estado: function () {
      this.setOptions();
    },
    departamento: function () {
      this.setOptions();
    },
  },
};
</script>

